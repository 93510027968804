import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from 'components/shared/Button'
import { TermsCheckboxes } from 'components/TransferRequest/TransferRequestForm/TermsCheckboxes'
import { termsValidator } from 'domain/user/validation'
import yup from 'lib/yup'
import { useForm } from 'react-hook-form'

export type FormValue = yup.Asserts<typeof termsValidator>

interface OnboardingTermsAndConditionsProps {
  onBackClick: () => void
  onFormSubmit: (values: FormValue) => void
}

export const OnboardingTermsAndConditions = ({ onBackClick, onFormSubmit }: OnboardingTermsAndConditionsProps) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(yup.object({ terms: termsValidator }).required()),
    shouldFocusError: true,
    defaultValues: {
      terms: {
        informedDecision: false,
        release: false,
        satisfactionOfObligations: false,
        soleControl: false,
        tax: false,
        transferAuthorization: false,
        sanctions: false,
        walletAddress: false,
        informedDecisionText: '',
        releaseText: '',
        satisfactionOfObligationsText: '',
        soleControlText: '',
        taxText: '',
        transferAuthorizationText: '',
        sanctionsText: '',
        walletAddressText: '',
      },
    },
  })

  const { terms } = watch()

  return (
    <form onSubmit={handleSubmit(values => onFormSubmit(values.terms))}>
      <h1 className="text-center text-lg leading-6 font-medium mb-2">Terms & Conditions</h1>
      <p className={`${errors.terms ? 'text-red-500' : 'text-gray-500'} text-sm text-center mb-6`}>
        Indicate your agreement with the following terms and conditions.
      </p>
      <div className="max-h-96 overflow-y-scroll p-1">
        <TermsCheckboxes terms={terms} setValue={setValue} errors={errors} register={register} />
      </div>
      <div className="flex gap-3 pt-4">
        <Button variant="outline" disabled={isSubmitting} loading={isSubmitting} onClick={onBackClick}>
          Back
        </Button>
        {/* @ts-ignore */}
        <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
          Next
        </Button>
      </div>
    </form>
  )
}
